import { Component } from '@angular/core';
import { AngularFireDatabase  } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})

export class AppComponent {
  private honks: Observable<any[]>;
  private falcos: Observable<any[]>;
  private page: string;

  ngOnInit() { }

  constructor(db: AngularFireDatabase) {
    this.page = "honk";

    this.falcos = db.list('january-falco').valueChanges();
    this.honks = db.list('january-honkctl').valueChanges();

  }


  showPage(page:string) {
    this.page = page;
  }

}

