import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'honk',
  templateUrl: './honk.component.html',
  styleUrls: ['./honk.component.css']
})
export class HonkComponent implements OnInit {
  @Input() public honk: any
  private hide: boolean
  constructor() { 
  }

  ngOnInit() {
    this.hide = true;
  }

  toggleResponse() {
    this.hide = !this.hide;
  }

}
