import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'falco',
  templateUrl: './falco.component.html',
  styleUrls: ['./falco.component.css']
})
export class FalcoComponent implements OnInit {
  @Input() public falco: Observable<any[]>;
  private falcos: any[];
  private hide: boolean
  constructor() {   }

  ngOnInit() {
    this.hide = true;
  }

  toggleResponse() {
    this.hide = !this.hide;
  }

}
